<template>
  <div class="page full-height">
    <van-nav-bar
            title="我的订单"
            left-text="返回"
            @click-left="() => {this.$router.go(-1)}"
            left-arrow
    />
    <van-tabs v-model="active" :swipe-threshold="5" @change="tabChange"  color="#07c160">
      <van-tab title="全部" :name="''">
        <order-item :item="item" :key="item.orderNo" v-for="item in orderList.data"/>
      </van-tab>
      <van-tab title="待接单" :name="1010">
        <order-item :item="item" :key="item.orderNo" v-for="item in orderList.data"/>
      </van-tab>
      <van-tab title="已接单" :name="1030">
        <order-item :item="item" :key="item.orderNo" v-for="item in orderList.data"/>
      </van-tab>
      <van-tab title="已完成" :name="1050">
        <order-item :item="item" :key="item.orderNo" v-for="item in orderList.data"/>
      </van-tab>
      <van-tab title="已取消" :name="1060">
        <order-item :item="item" :key="item.orderNo" v-for="item in orderList.data"/>
      </van-tab>
    </van-tabs>
  </div>
</template>

<script>
  import {Tabs, Tab,NavBar} from 'vant';
  import orderItem  from "../../components/order/OrderItem";
  import {mapState} from "vuex";
  export default {
    name: "orderList",
    components:{
      vanTabs:Tabs,
      vanTab:Tab,
      orderItem,
      vanNavBar:NavBar,
    },
    data(){
      return {
        active:0,
        orderList:{
          loading: false,
          finished: false,
          data: [],
          page: 1,
          size: 10
        }
      }
    },
    computed:{
      ...mapState({
        fromCompany:state => state.app.fromCompany
      })
    },
    methods:{
      tabChange(){
        this.orderList = {
          loading: false,
          finished: false,
          data: [],
          page: 1,
          size: 10
        };
        this.loadOrderList()
      },
      adapterOrderStatus(){
        //根据
        switch (this.active) {
          case 1010:
            return {orderStatus:1010};
          case 1030:
            return {orderStatus:1030};
          case 1050:
            return {orderStatus:1050};
          case 1060:
            return {orderStatus:1060};
          default:
            return null
        }
      },
      loadOrderList(){
        const param = {
          params: Object.assign(
            {
              page: this.orderList.page,
              size: this.orderList.size,
            },
            this.adapterOrderStatus()
          )
        };
        this.$http.get("/orders",param).then(resp => {
          if(resp.success){
            this.orderList.finished = resp.data.content.length < this.orderList.size;
            if (!this.orderList.finished) {
              this.orderList.page++;
            }
            this.orderList.data = this.orderList.data.concat(resp.data.content);
          }
        }).catch(error => {
          console.log(error);
        }).finally(() => {
          this.orderList.loading = false;
        })
      }
    },
    mounted() {
      this.loadOrderList();
    }
  }
</script>

<style  lang="less">

</style>

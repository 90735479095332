<template>
  <div class="order-item" >
    <div class="order-item__top" @click="toDetail">
      <div class="order-item__outlet-name">{{item.serviceStoreName}}</div>
      <div class="order-item__status">
        <van-tag v-if="item.orderStatus === 1050" mark type="success">已完成</van-tag>
        <van-tag v-if="item.orderStatus === 1061" mark type="success">已归档</van-tag>
        <van-tag v-if="item.orderStatus === 1010" mark>待接单</van-tag>
        <van-tag v-if="item.orderStatus === 1030" mark type="success">待执行</van-tag>
        <van-tag v-if="item.orderStatus === 1040" mark type="warning">执行中</van-tag>
        <van-tag v-if="item.orderStatus === 1060" mark type="danger">已取消</van-tag>
      </div>
    </div>

    <div class="order-item__middle" @click="toDetail">
      <van-image class="order-item__outlet-cover" :src="coverImage"/>
      <div class="order-item__info">
        <div class="order-item__info-item">
          预约服务：<span>{{item.orderTypeName}}</span>
        </div>

        <div class="order-item__info-item text-ellipsis-2lines" >
          {{addressLabel}}：<span>{{address}}</span>
        </div>
        <div class="order-item__info-item">
          预约时间：<span>{{item.planTime}}</span>
        </div>
        <div class="order-item__info-item" >
          服务人员：<span v-if="item.currentServiceWorkerName != null">{{item.currentServiceWorkerName}}</span>
          <span v-if="item.currentServiceWorkerName == null">暂未领取</span>
        </div>
      </div>
    </div>
    <div class="order-item__actions" v-if="item.orderStatus < 1050">
      <van-button size="small" type="primary" v-if="item.orderStatus === 1010" @click="openLocation">导航去</van-button>
      <van-button size="small" type="danger" v-if="item.orderStatus === 1010" @click="cancelOrder">取消</van-button>
      <van-button size="small" type="danger" v-if="item.orderStatus === 1030" @click="cancelOrder">取消</van-button>
      <van-button size="small" type="primary" v-if="item.orderStatus === 1030"  @click="openLocation">导航去</van-button>
    </div>

  </div>
</template>

<script>
  import {Image, Tag, Button, Toast, Notify} from 'vant';
  import {IMG_DOMAIN} from '../../config';

  export default {
    name: "orderItem",
    props: { item:Object},
    components: {
      vanImage: Image,
      vanTag:Tag,
      vanButton:Button
    },
    computed:{
      address(){
        return this.item.orderType === 17 ? this.item.destinationAddress : this.item.houseAddress;
      },
      addressLabel(){
        return this.item.orderType === 17 ? "预约地址" : "上门地址";
      },
      coverImage(){
        if(this.item.coverImage && this.item.coverImage.url){
          return `${IMG_DOMAIN}${this.item.coverImage.url}`;
        }
        return "";
      },
    },
    methods:{
      toDetail(){
        const orderNo = this.item.orderNo;
        this.$router.push(`/order/${orderNo}`);
      },
      openLocation(){
        const param = {
          latitude: parseFloat(this.item.lat), // 纬度，浮点数，范围为90 ~ -90
          longitude: parseFloat(this.item.lng), // 经度，浮点数，范围为180 ~ -180。
          name: this.item.storeName, // 位置名
          address: this.item.destinationAddress, // 地址详情说明
          scale: 12
        };
        this.$wechat.openLocation(param);
      },
      cancelOrder(){
        const orderNo = this.item.orderNo;
        this.$http.put(`/order/${orderNo}/close`, {
          remark:'用户主动取消'
        }).then(resp => {
            if(resp.success){
              Toast("取消成功");
            } else {
              Notify(resp.message);
            }
        })
      }
    }
  }
</script>

<style  lang="less">
  @import "../../assets/less/variable";


  .order-item {
    display: flex;
    flex-direction: column;
    padding: 15px;
    font-size: @primary-font-size;
    background-color: @base-block__background;


    &__top {
      display: flex;
      justify-content: space-between;
      height: 30px;
      align-items: center;
    }

    &__outlet-cover{
      width: 110px;
      margin-right:10px;
    }

    &__middle {
      display: flex;
      height: 90px;
    }

    &__actions {
      margin-top:@padding;
      height: 50px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      border-top:1px solid #EAEAEA;
      .van-button + .van-button{
        margin-left:@padding
      }
    }

    &__info{
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      &-item{
        line-height:1.2;
        font-size: 14px;
      }
    }
  }

  .order-item ~ .order-item{
    margin-top:10px;
  }
</style>
